exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-page-index-tsx": () => import("./../../../src/templates/page/index.tsx" /* webpackChunkName: "component---src-templates-page-index-tsx" */),
  "component---src-templates-post-category-index-tsx": () => import("./../../../src/templates/post/category/index.tsx" /* webpackChunkName: "component---src-templates-post-category-index-tsx" */),
  "component---src-templates-post-list-index-tsx": () => import("./../../../src/templates/post/list/index.tsx" /* webpackChunkName: "component---src-templates-post-list-index-tsx" */),
  "component---src-templates-post-single-index-tsx": () => import("./../../../src/templates/post/single/index.tsx" /* webpackChunkName: "component---src-templates-post-single-index-tsx" */),
  "component---src-templates-project-category-index-tsx": () => import("./../../../src/templates/project/category/index.tsx" /* webpackChunkName: "component---src-templates-project-category-index-tsx" */),
  "component---src-templates-project-list-index-tsx": () => import("./../../../src/templates/project/list/index.tsx" /* webpackChunkName: "component---src-templates-project-list-index-tsx" */),
  "component---src-templates-project-single-index-tsx": () => import("./../../../src/templates/project/single/index.tsx" /* webpackChunkName: "component---src-templates-project-single-index-tsx" */),
  "component---woocommerce-src-templates-archive-index-js": () => import("./../../../woocommerce/src/templates/archive/index.js" /* webpackChunkName: "component---woocommerce-src-templates-archive-index-js" */),
  "component---woocommerce-src-templates-cart-index-js": () => import("./../../../woocommerce/src/templates/cart/index.js" /* webpackChunkName: "component---woocommerce-src-templates-cart-index-js" */),
  "component---woocommerce-src-templates-checkout-index-js": () => import("./../../../woocommerce/src/templates/checkout/index.js" /* webpackChunkName: "component---woocommerce-src-templates-checkout-index-js" */),
  "component---woocommerce-src-templates-my-account-index-js": () => import("./../../../woocommerce/src/templates/my-account/index.js" /* webpackChunkName: "component---woocommerce-src-templates-my-account-index-js" */),
  "component---woocommerce-src-templates-product-index-js": () => import("./../../../woocommerce/src/templates/product/index.js" /* webpackChunkName: "component---woocommerce-src-templates-product-index-js" */),
  "component---woocommerce-src-templates-shop-index-js": () => import("./../../../woocommerce/src/templates/shop/index.js" /* webpackChunkName: "component---woocommerce-src-templates-shop-index-js" */),
  "component---woocommerce-src-templates-wishlist-index-js": () => import("./../../../woocommerce/src/templates/wishlist/index.js" /* webpackChunkName: "component---woocommerce-src-templates-wishlist-index-js" */)
}

