/**
 * Internal dependencies.
 */
import fetch from "isomorphic-fetch";
import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink } from "@apollo/client";
import { isEmpty } from "lodash";
import decode from "jwt-decode";

// if ( process.browser ) {
// 	console.warn( 'hey', process.env.GATSBY_WORDPRESS_SITE_URL );
// }
const JWT_KEY = `woo-session`;
const API_URL = `${process.env.GATSBY_WP_ADDRESS}/graphql`;
export const isTokenExpired = (token) => decode(token).exp <= Date.now() / 1000;
/**
 * Middleware operation
 *
 * If we have a session token in localStorage, add it to the GraphQL request as a Session header.
 * If we have a auth token in localStorage, add it to the GraphQL request as a authorization header.
 */
export const middleware = new ApolloLink((operation, forward) => {
  let headersData = null;

  /**
   * If session data exist in local storage, set value as session header.
   */
  const session = typeof window !== `undefined` ? localStorage.getItem(JWT_KEY) : null;

  if (!isEmpty(session)) {
    headersData = {
      "woocommerce-session": `Session ${session}`,
    };
  }

  /**
   * If auth token exist in local storage, set value as authorization header.
   */
  const auth = typeof window !== `undefined` ? JSON.parse(localStorage.getItem("auth")) : null;
  const token = !isEmpty(auth) ? auth.authToken : null;

  if (!isEmpty(token)) {
    headersData = {
      ...headersData,
      authorization: token ? `Bearer ${token}` : "",
    };
  }

  if (!isEmpty(headersData)) {
    operation.setContext(({ headers = {} }) => ({
      headers: headersData,
    }));
  }

  return forward(operation);
});

/**
 * Afterware operation.
 *
 * This catches the incoming session token and stores it in localStorage, for future GraphQL requests.
 */
export const afterware = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    /**
     * Check for session header and update session in local storage accordingly.
     */
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;
    const session = headers.get("woocommerce-session");

    //console.log('session:',session);
    console.log('Client isTokenExpired:',isTokenExpired(session));
    // console.log('will expire:', decode(session).exp );
    // console.log('will expire:', Date.now() / 1000);

    if (session) {
      // Remove session data if session destroyed.
      if ("false" === session) {
        localStorage.removeItem(JWT_KEY);
        localStorage.removeItem("auth");

        // Update session new data if changed.
      } else if (localStorage.getItem(JWT_KEY) !== session) {
        localStorage.setItem(JWT_KEY, headers.get("woocommerce-session"));
      }
    }

    return response;
  });
});

// Apollo GraphQL client.
export const client = new ApolloClient({
  link: middleware.concat(
    afterware.concat(
      createHttpLink({
        uri: API_URL,
        fetch: fetch,
      }),
    ),
  ),
  cache: new InMemoryCache(),
});
