import React, { useState, useEffect } from "react";
//import { isTokenExpired } from "../../utils/functions";
//import decode from "jwt-decode";

export const AppContext = React.createContext("woocommerce");

export const AppProvider = (props) => {
  const [cart, setCart] = useState(null);
  const [wishlist, setWishlist] = useState(null);
  const [auth, setAuth] = useState(null);
  // const [session, setSession] = useState(null);
  // const [countdown, setCountdown] = useState(100);

  // useEffect(() => {
  //   if (typeof window !== `undefined`) {
  //     let sessionData = localStorage.getItem("woo-session");
  //     sessionData = null !== sessionData ? sessionData : null;
  //     setSession(sessionData);
  //   }
  // }, [setSession]);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      let authData = localStorage.getItem("auth");
      authData = null !== authData ? JSON.parse(authData) : null;
      setAuth(authData);
    }
  }, [setAuth]);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      let wishlistData = localStorage.getItem("woo-wishlist");
      wishlistData = null !== wishlistData ? JSON.parse(wishlistData) : null;
      setWishlist(wishlistData);
    }
  }, [setWishlist]);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      let cartData = localStorage.getItem("woo-cart");
      cartData = null !== cartData ? JSON.parse(cartData) : null;
      setCart(cartData);
    }
  }, [setCart]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setCountdown(countdown - 1);
  //     if (session) {
  //       console.log('session:',session);
  //       console.log('A:',decode(session).exp);
  //       console.log('B:',Date.now() / 1000);
  //       console.log("Session TokenExpired:",isTokenExpired(session));
  //       if (isTokenExpired(session)) {
  //         console.log('Renew session');
  //         setSession(null);
  //       }
  //     }
  //   }, 10000);
  //   return () => clearTimeout(timer);
  // },[countdown]);

  return (
    <AppContext.Provider value={{ auth, setAuth, cart, setCart, wishlist, setWishlist }}>
      {props.children}
    </AppContext.Provider>
  );
};
